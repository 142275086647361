import { IonText } from "@ionic/react";
import { ProfessionalReferenceStatus } from "@src/app/professionalReferences/constants";
import { IReferenceHeaderBannerProps } from "@src/lib/interface/src/lib/professionalReference";
import { useCallback, useEffect, useState } from "react";

const ReferenceHeaderBanner = ({ professionalReferences }: IReferenceHeaderBannerProps) => {
  const [completedReferences, setCompletedReferences] = useState<number>(0);

  useEffect(() => {
    if (professionalReferences && professionalReferences?.references.length > 0) {
      const verifiedCount = professionalReferences.references.filter(
        ({ status }) => status === ProfessionalReferenceStatus.VERIFIED
      ).length;
      setCompletedReferences(verifiedCount);
    }
  }, [professionalReferences]);

  const pluralizedHeaderText = useCallback((): string => {
    const text =
      "Add %PROFESSIONAL_REFERENCES% below. After you enter their information, we will prompt you to send them an invitation to complete the reference.";
    if (professionalReferences) {
      return text.replace(
        "%PROFESSIONAL_REFERENCES%",
        `${professionalReferences.minVerifiedReferenceRequired} professional reference${
          professionalReferences.minVerifiedReferenceRequired > 1 ? "s" : ""
        }`
      );
    }
    return "";
  }, [professionalReferences]);

  const completedReferencesText = useCallback((): string => {
    return `${completedReferences} of ${professionalReferences?.minVerifiedReferenceRequired} references completed! Please complete your remaining references.`;
  }, [completedReferences, professionalReferences]);

  if (!professionalReferences) {
    return null;
  }

  return (
    <>
      {completedReferences > 0 && professionalReferences.completed ? (
        <div className="success-banner">
          Reference check{completedReferences > 1 ? "s" : ""} completed!
        </div>
      ) : (
        <div className="header-banner">
          {completedReferences > 0 && !professionalReferences.completed ? (
            <IonText>{completedReferencesText()}</IonText>
          ) : (
            <IonText>{pluralizedHeaderText()}</IonText>
          )}
        </div>
      )}
    </>
  );
};

export { ReferenceHeaderBanner };
