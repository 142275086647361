import { IReferenceErrorBannerComponentProps } from "@src/lib/interface/src/lib/professionalReference";
import { ForwardedRef, forwardRef } from "react";

const ReferenceErrorBannerComponent = (
  { showErrorBanner, rejectedReferenceCount }: IReferenceErrorBannerComponentProps,
  ref?: ForwardedRef<HTMLDivElement>
) => {
  return (
    <>
      {showErrorBanner && (
        <div className="error-banner" ref={ref}>
          <div className="content">
            {(rejectedReferenceCount ?? 0) > 1
              ? "Your references were unable to verify your work history. Please add new references."
              : "Your reference was unable to verify your work history. Please add a new reference."}
          </div>
        </div>
      )}
    </>
  );
};

const ReferenceErrorBanner = forwardRef(ReferenceErrorBannerComponent);

export { ReferenceErrorBanner };
