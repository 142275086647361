import { isDefined } from "@clipboard-health/util-ts";
import { DatetimeChangeEventDetail, isPlatform } from "@ionic/core";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonDatetime,
  IonInput,
  IonLabel,
  IonNote,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { createProfessionalReference } from "@src/app/professionalReferences/api";
import {
  DEFAULT_TIMER,
  DEFAULT_TIMER_MULTIPLIER,
  ISO_8601_SHORT_DATE_FORMAT,
  ReferenceFormNames,
} from "@src/app/professionalReferences/constants";
import { ReferenceErrorBanner } from "@src/app/professionalReferences/referenceErrorBanner";
import {
  INITIAL_STATE,
  getErrorFields,
  getFirstOfMonthDateInIso8601Format,
} from "@src/app/professionalReferences/referenceForm/formHandler";
import { appendLabelToIonDateTime } from "@src/app/professionalReferences/util";
import { logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import {
  ErrorFields,
  Form,
  IReferenceFormProps,
} from "@src/lib/interface/src/lib/professionalReference";
import moment from "moment";
import { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";

const ReferenceForm: FC<IReferenceFormProps> = (props: IReferenceFormProps) => {
  const {
    count,
    postSubmitFormAction,
    professionalReference,
    firstForm,
    showErrorBanner,
    rejectedReferenceCount,
    fetchReferences,
    professionalReferencesCount,
    focusOnFirstField,
    isScrollingToFirstFormEffectEnabled,
  } = props;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const datePickerLabelTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [form, setForm] = useState<Form>(
    professionalReference
      ? {
          ...professionalReference,
          employmentStartDate: getFirstOfMonthDateInIso8601Format({
            zeroIndexedMonth: professionalReference.fromMonth,
            fourDigitYear: professionalReference.fromYear,
          }),
          employmentEndDate: getFirstOfMonthDateInIso8601Format({
            zeroIndexedMonth: professionalReference.toMonth,
            fourDigitYear: professionalReference.toYear,
          }),
        }
      : INITIAL_STATE
  );
  const [errors, setErrors] = useState<ErrorFields>({});
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [minDate, setMinDate] = useState<string>();
  const [maxDate, setMaxDate] = useState<string>(moment().format(ISO_8601_SHORT_DATE_FORMAT));
  const [doneInitialFocus, setDoneInitialFocus] = useState<boolean>(false);

  const refObj = {
    [ReferenceFormNames.employerName]: useRef<HTMLIonInputElement>(null),
    [ReferenceFormNames.workerTitle]: useRef<HTMLIonInputElement>(null),
    [ReferenceFormNames.employmentStartDate]: useRef<HTMLIonDatetimeElement>(null),
    [ReferenceFormNames.employmentEndDate]: useRef<HTMLIonDatetimeElement>(null),
    [ReferenceFormNames.referenceFirstName]: useRef<HTMLIonInputElement>(null),
    [ReferenceFormNames.referenceLastName]: useRef<HTMLIonInputElement>(null),
  };

  const errorBannerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLIonCardTitleElement>(null);

  const employerNameRef = refObj[ReferenceFormNames.employerName];

  // This sets the cursor focus on the first input field of the form
  useEffect(() => {
    let timeout;
    if (
      firstForm &&
      !professionalReference &&
      focusOnFirstField &&
      refObj[ReferenceFormNames.employerName].current
    ) {
      timeout = setTimeout(() => {
        refObj[ReferenceFormNames.employerName].current?.setFocus?.();
        if (isScrollingToFirstFormEffectEnabled) {
          setDoneInitialFocus(true);
        }
      }, DEFAULT_TIMER);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, professionalReference, employerNameRef, isScrollingToFirstFormEffectEnabled]);

  useEffect(() => {
    let timeout;

    if (doneInitialFocus) {
      timeout = setTimeout(() => {
        if (firstForm && !professionalReference) {
          if (showErrorBanner) {
            errorBannerRef.current?.scrollIntoView({ behavior: "smooth" });
          } else {
            titleRef.current?.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, DEFAULT_TIMER);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doneInitialFocus]);

  useEffect(() => {
    if (submitted) {
      const errorFields = getErrorFields(form);
      setErrors(errorFields);
    }
  }, [form, submitted]);

  useEffect(() => {
    let timer;
    if (form.employmentStartDate) {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        // This is a hack to get around the fact that the date picker does not have a setFocus() method, so we have to click on it instead
        refObj[ReferenceFormNames.employmentEndDate].current?.click();
      }, DEFAULT_TIMER);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.employmentStartDate]);

  const handleSubmit = async () => {
    setSubmitted(true);
    const errorFields = getErrorFields(form);
    if (Object.values(errorFields).flat().length > 0) {
      setErrors(errorFields);
      const firstError = Object.entries(errorFields).find((item) => item[1].flat().length > 0);
      if (firstError) {
        const [inputName] = firstError;
        if (
          [ReferenceFormNames.employmentStartDate, ReferenceFormNames.employmentEndDate].includes(
            inputName as ReferenceFormNames
          )
        ) {
          setDateTimeFocus(refObj[inputName]);
        } else {
          setInputFocus(refObj[inputName]);
        }
      }
      return;
    }
    const fromDate = moment.utc(form.employmentStartDate);
    const toDate = moment.utc(form.employmentEndDate);

    const { employmentEndDate, employmentStartDate, ...rest } = form;
    setIsSubmitting(true);
    const response = await createProfessionalReference({
      ...rest,
      toMonth: Number(toDate.format("M")),
      toYear: Number(toDate.format("YYYY")),
      fromMonth: Number(fromDate.format("M")),
      fromYear: Number(fromDate.format("YYYY")),
    });
    logEvent(USER_EVENTS.REQUEST_REFERENCE_BUTTON_TAPPED, {
      reference_id: response?.data?._id,
      reference_number: (professionalReferencesCount ?? 0) + 1,
    });

    setIsSubmitting(false);
    await fetchReferences?.();
    postSubmitFormAction?.(response);
  };

  const handleDateInputChange = (
    event: CustomEvent<DatetimeChangeEventDetail>,
    name: ReferenceFormNames.employmentStartDate | ReferenceFormNames.employmentEndDate
  ) => {
    const eventValue = event?.detail?.value;

    if (!isDefined(eventValue)) {
      return;
    }
    // the event value is in the format YYYY-MM-DDTHH:mm:ss.sssZ, and we only want the YYYY-MM part of it
    const [year, month] = eventValue.split("-");

    const date = getFirstOfMonthDateInIso8601Format({
      zeroIndexedMonth: parseInt(month, 10) - 1,
      fourDigitYear: parseInt(year, 10),
    });

    setForm({
      ...form,
      [name]: date,
    });
  };

  const scrollTitleIntoView = useCallback((): void => {
    setTimeout(() => {
      titleRef.current?.scrollIntoView({ behavior: "smooth" });
    }, DEFAULT_TIMER * DEFAULT_TIMER_MULTIPLIER);
  }, [titleRef]);

  const setInputFocus = useCallback(
    (ref: RefObject<HTMLIonInputElement>): void => {
      setTimeout(() => {
        ref.current?.setFocus();
      }, DEFAULT_TIMER);
      scrollTitleIntoView();
    },
    [scrollTitleIntoView]
  );

  const setDateTimeFocus = useCallback((ref: RefObject<HTMLIonInputElement>): void => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
    ref.current?.click();
  }, []);

  const onInputKeyPress = (event, nextRef) => {
    const isDateField = [
      refObj[ReferenceFormNames.employmentStartDate],
      refObj[ReferenceFormNames.employmentEndDate],
    ].includes(nextRef);
    if (event.key === "Enter") {
      if (isDateField) {
        nextRef.current.click();
      } else {
        nextRef.current?.setFocus();
      }
    }
  };

  const onDateTimeFocus = useCallback((_: CustomEvent<void>, label: string) => {
    if (datePickerLabelTimeoutRef.current) {
      clearTimeout(datePickerLabelTimeoutRef.current);
    }
    datePickerLabelTimeoutRef.current = setTimeout(() => {
      const element = document.querySelector(".picker-toolbar-cancel");
      if (element) {
        appendLabelToIonDateTime(element, label);
      }
    }, DEFAULT_TIMER);
  }, []);

  return (
    <div className={`reference-form-content ${isPlatform("ios") && "reference-form-content-ios"}`}>
      <ReferenceErrorBanner
        ref={errorBannerRef}
        showErrorBanner={showErrorBanner ?? false}
        rejectedReferenceCount={rejectedReferenceCount ?? 0}
      />
      {!professionalReference && (
        <IonCardTitle ref={titleRef} className="title">
          Reference {count}
        </IonCardTitle>
      )}
      <IonCard>
        <IonCardContent>
          <div>
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F" position="stacked">
                <p>EMPLOYER NAME</p>
              </IonLabel>
              <IonInput
                data-testid={`add-employer-name`}
                onKeyPress={(event) =>
                  onInputKeyPress(event, refObj[ReferenceFormNames.workerTitle])
                }
                className="form-input"
                ref={refObj[ReferenceFormNames.employerName]}
                disabled={!!professionalReference}
                value={form.employerName}
                autocapitalize="on"
                onIonChange={(event) =>
                  setForm({
                    ...form,
                    [ReferenceFormNames.employerName]: event.detail.value ?? "",
                  })
                }
                type="text"
                required
              />
              {errors?.[ReferenceFormNames.employerName]?.[0]?.isValid === false && (
                <IonNote slot="error" data-testid={"error-message-employer-name"}>
                  {errors?.[ReferenceFormNames.employerName]?.[0]?.message}
                </IonNote>
              )}
            </div>
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>YOUR TITLE/ROLE</p>
              </IonLabel>
              <IonInput
                data-testid={`add-worker-title`}
                className="form-input"
                disabled={!!professionalReference}
                onKeyPress={(event) =>
                  onInputKeyPress(event, refObj[ReferenceFormNames.employmentStartDate])
                }
                value={form.workerTitle}
                autocapitalize="on"
                ref={refObj[ReferenceFormNames.workerTitle]}
                onIonChange={(event) =>
                  setForm({
                    ...form,
                    [ReferenceFormNames.workerTitle]: event.detail.value ?? "",
                  })
                }
                type="text"
                required
              />
              {errors?.[ReferenceFormNames.workerTitle]?.[0]?.isValid === false && (
                <IonNote data-testid={"error-message-worker-title"} slot="error">
                  {errors?.[ReferenceFormNames.workerTitle]?.[0]?.message}
                </IonNote>
              )}
            </div>
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>DATES OF EMPLOYMENT</p>
              </IonLabel>
              <IonRow>
                <IonCol className="date-column" sizeXs="5">
                  <div>
                    <IonDatetime
                      data-testid="employment-start-date"
                      className={"date-time-picker"}
                      ref={refObj[ReferenceFormNames.employmentStartDate]}
                      disabled={!!professionalReference}
                      value={form.employmentStartDate}
                      max={maxDate}
                      displayFormat="MMMM YYYY"
                      onIonFocus={(event) => {
                        onDateTimeFocus(event, "Employment Start");
                      }}
                      onIonChange={(event) => {
                        handleDateInputChange(event, ReferenceFormNames.employmentStartDate);
                        setMinDate(moment(event.detail.value).format(ISO_8601_SHORT_DATE_FORMAT));
                      }}
                    />
                    {errors?.[ReferenceFormNames.employmentStartDate]?.[0]?.isValid === false && (
                      <IonNote slot="error">
                        {errors?.[ReferenceFormNames.employmentStartDate]?.[0]?.message}
                      </IonNote>
                    )}
                  </div>
                </IonCol>
                <IonCol sizeXs="2" style={{ textAlign: "center" }}>
                  <div className="date-input-divider">
                    <div />
                  </div>
                </IonCol>
                <IonCol className="date-column" sizeXs="5">
                  <div style={{ width: "100%" }}>
                    <IonDatetime
                      data-testid="employment-end-date"
                      ref={refObj[ReferenceFormNames.employmentEndDate]}
                      displayFormat="MMMM YYYY"
                      value={form.employmentEndDate}
                      min={minDate}
                      max={moment().format(ISO_8601_SHORT_DATE_FORMAT)}
                      disabled={!!professionalReference}
                      className={"date-time-picker"}
                      onIonFocus={(event) => onDateTimeFocus(event, "Employment End")}
                      onIonChange={(event) => {
                        handleDateInputChange(event, ReferenceFormNames.employmentEndDate);
                        setMaxDate(moment(event.detail.value).format(ISO_8601_SHORT_DATE_FORMAT));
                        setInputFocus(refObj[ReferenceFormNames.referenceFirstName]);
                      }}
                    />
                    {errors?.[ReferenceFormNames.employmentEndDate]?.[0]?.isValid === false && (
                      <IonNote slot="error">
                        {errors?.[ReferenceFormNames.employmentEndDate]?.[0]?.message}
                      </IonNote>
                    )}
                  </div>
                </IonCol>
              </IonRow>
            </div>
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>REFERENCE FIRST NAME</p>
              </IonLabel>
              <IonInput
                data-testid={`reference-first-name`}
                disabled={!!professionalReference}
                ref={refObj[ReferenceFormNames.referenceFirstName]}
                value={form.referenceFirstName}
                autocapitalize="on"
                onKeyPress={(event) =>
                  onInputKeyPress(event, refObj[ReferenceFormNames.referenceLastName])
                }
                onIonChange={(event) =>
                  setForm({
                    ...form,
                    [ReferenceFormNames.referenceFirstName]: event.detail.value ?? "",
                  })
                }
                className="form-input"
                type="text"
                required
              />
              {errors?.[ReferenceFormNames.referenceFirstName]?.[0]?.isValid === false && (
                <IonNote slot="error">
                  {errors?.[ReferenceFormNames.referenceFirstName]?.[0]?.message}
                </IonNote>
              )}
            </div>
            <div className="form-control">
              <IonLabel class="ion-text-wrap" color="#4F4F4F">
                <p>REFERENCE LAST NAME</p>
              </IonLabel>
              <IonInput
                data-testid={`reference-last-name`}
                ref={refObj[ReferenceFormNames.referenceLastName]}
                disabled={!!professionalReference}
                value={form.referenceLastName}
                autocapitalize="on"
                onIonChange={(event) =>
                  setForm({
                    ...form,
                    [ReferenceFormNames.referenceLastName]: event.detail.value ?? "",
                  })
                }
                className="form-input"
                type="text"
                required
              />
              {errors?.[ReferenceFormNames.referenceLastName]?.[0]?.isValid === false && (
                <IonNote slot="error">
                  {errors?.[ReferenceFormNames.referenceLastName]?.[0]?.message}
                </IonNote>
              )}
            </div>
          </div>
        </IonCardContent>
      </IonCard>
      {!professionalReference && (
        <div>
          <IonButton
            data-testid="request-reference-button"
            expand="block"
            size="large"
            className="ion-margin-top ion-margin-bottom continue-button reference-button"
            disabled={Object.values(errors).flat().length > 0 || (isSubmitting && submitted)}
            onClick={handleSubmit}
          >
            Email/Text Reference{" "}
            {isSubmitting && (
              <span>
                {" "}
                <IonSpinner class="ion-margin-start" data-testid="loading-indicator" />
              </span>
            )}
          </IonButton>
        </div>
      )}
    </div>
  );
};

export { ReferenceForm };
