import { isPlatform } from "@ionic/core";

type WordMap = Record<number, string>;

const ones: WordMap = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
};

const numberToWords = (num: number): string => {
  if (num < 0 || num > 9) {
    return "Number out of range";
  }

  return ones[num];
};

const appendLabelToIonDateTime = (sibling: Element, label: string) => {
  if (sibling) {
    const identifier = "date-time-label";
    const oldElement = document.getElementById(identifier);
    if (oldElement) {
      oldElement.remove();
    }
    const ionLabel = document.createElement("ion-label");
    ionLabel.setAttribute("id", identifier);
    ionLabel.setAttribute("class", identifier);
    ionLabel.innerHTML = label;
    if (isPlatform("ios")) {
      sibling.insertAdjacentElement("afterend", ionLabel);
    } else {
      ionLabel.style.position = "absolute";
      sibling.insertAdjacentElement("beforebegin", ionLabel);
    }
  }
};

export { numberToWords, appendLabelToIonDateTime };
