import { IonButton, IonFooter, IonIcon } from "@ionic/react";
import { useKeyboardState } from "@src/app/professionalReferences/hook/useKeyboardState";
import { numberToWords } from "@src/app/professionalReferences/util";
import { IReferenceInfoCardProps } from "@src/lib/interface/src/lib/professionalReference";
import { add } from "ionicons/icons";
import { FC } from "react";

const ReferenceInfoCard: FC<IReferenceInfoCardProps> = (props: IReferenceInfoCardProps) => {
  const { minVerifiedReferenceRequired, onAddReference } = props;
  const { isKeyboardOpen } = useKeyboardState();
  if (isKeyboardOpen) {
    return null;
  }
  return (
    <IonFooter>
      <div className="reference-info-card">
        We only require {numberToWords(minVerifiedReferenceRequired)} reference
        {minVerifiedReferenceRequired > 1 ? "s" : ""}, but you may add more.
      </div>
      <div>
        <IonButton
          data-testid="request-reference-button"
          expand="block"
          fill="outline"
          size="large"
          className="ion-margin-top ion-margin-bottom continue-button reference-info-button reference-button"
          onClick={onAddReference}
        >
          <IonIcon icon={add} mode="ios" /> Add Reference
        </IonButton>
      </div>
    </IonFooter>
  );
};

export { ReferenceInfoCard };
